<script setup lang="ts">
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';
import {useScriptTag} from '@vueuse/core';
import useCpBem from '~/composables/useCpBem';
import CpLogo from '~/components/cp/CpLogo/CpLogo.vue';
import CpModal from '~/components/cp/CpModal/CpModal.vue';
import CpSectionHeader from '~/components/cp/CpSection/CpSectionHeader/CpSectionHeader.vue';
import CpDivider from '~/components/cp/CpDivider/CpDivider.vue';
import {useAuthActions} from '~/composables';

const props = defineProps<{
  show: boolean
  showInvalidError?: boolean
}>();

const emit = defineEmits(['login', 'register', 'update:show']);

const {b, e} = useCpBem('cp-modal-sign-in');
const {t} = useI18n();
const {handleSuccessLogin} = useAuthActions();
const loading = ref(false);

const modalVisible = computed({
  get() {
    return props.show;
  },
  set(value) {
    emit('update:show', value);
  },
});

const register = () => closeModal();

function closeModal() {
  modalVisible.value = false;
}

async function recoverPassword() {
  modalVisible.value = false;
  await navigateTo({name: 'account-enter-mail-screen'});
}

const onSuccessThirdParty = (linkedAccount?: boolean) => {
  loading.value = false;
  if (linkedAccount) {
    navigateTo({name: 'link-accounts-welcome-back'});
    return;
  }
  // TODO: Check if this is necessary
  // handleSuccessLogin('home')
};

const googleScript = useScriptTag('https://accounts.google.com/gsi/client', () => {});
const appleScript = useScriptTag('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/es_MX/appleid.auth.js', () => {});

await Promise.all([googleScript.load(), appleScript.load()]);

</script>

<template>
    <div :class="b">
        <CpModal
            v-model:show="modalVisible"
            :class="e('modal')"
            fullscreen
            persistent
            scroll-strategy="block"
            without-enter-animation
            without-leave-animation
        >
            <CpSectionHeader
                type="h3"
                :title="t('login')"
                button="close"
                @header-action="closeModal()"
            />
            <div :class="e('body')" data-testid="modal-sign-in">
                <div :class="e('logo-container')">
                    <CpLogo />
                </div>
                <LazyCpFormSignIn
                    kind="Modal"
                    :show-a-p-i-error="showInvalidError"
                    error-kind="apiResponseNotCorrect"
                    @register="register"
                    @go-to-forgot-password="recoverPassword"
                />
                <CpDivider fancy />
                <div :class="e('fid-container')">
                    <LazyGoogleButton
                        @on-start="loading = true"
                        @on-suspense="loading = false"
                        @on-success="onSuccessThirdParty"
                        @on-success-link-accounts="onSuccessThirdParty(true)"
                    />
                    <LazyAppleButton
                        @on-start="loading = true"
                        @on-suspense="loading = false"
                        @on-success="onSuccessThirdParty"
                        @on-success-link-accounts="onSuccessThirdParty(true)"
                    />
                </div>
            </div>
            <CpLoader v-if="loading" kind="small" full-size />
        </CpModal>
    </div>
</template>

<style scoped lang="scss">
.cp-modal-sign-in {
  &__body {
    display: flex;
    flex-direction: column;
    padding: $cp-gutter;
    gap: $cp-gutter-xlarge;
    overflow-y: scroll;
    width: 100%;
    justify-content: center;
    align-items: center;
    > * {
      width: 100%;
    }
  }
  &__body::-webkit-scrollbar {
    width: 0;
  }
  &__logo-container {
    width: 223px;
    height: 48px;
  }
  &__fid-container {
    display: flex;
    flex-direction: column;
    gap: $cp-gutter;
  }
}
</style>

<i18n locale="es-US">
  login: 'Iniciar sesión'
</i18n>
